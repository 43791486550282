import logo from "./logo.svg";
import "./App.css";
import TypeWriterAnimation from "./TypeWriterAnimation";
import send from "./assets/send.png";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { doc } from "prettier";

function App() {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState(
    "Merhaba ben TOBI, sizin dijital yardımcınızım, size nasıl yardımcı olabilirim?"
  );
  const questionInput = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const sendMessage = () => {
    if (question === "") return;
    setShowSpinner(true);
    setAnswer("");
    axios
      .post("https://server1.sncr.dev/conversation", { human_input: question })
      .then((res) => {
        console.log("res", res.data);
        setAnswer(res.data.output);
        setQuestion("");
        setShowSpinner(false);
      });
  };

  return (
    <div className="App">
      <div className="top">
        <TypeWriterAnimation
          onAnimationFinished={() => console.log("finished")}
          answer={answer}
        />
        {showSpinner && (
          <div className="loaderContainer">
            <span class="loader"></span>
          </div>
        )}
      </div>
      <div className="bottom">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage();
          }}
        >
          <input
            id="questionInput"
            ref={questionInput}
            className="questionInput"
            type="text"
            placeholder="Mesajınızı yazın"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </form>

        <div className="sendButton" onClick={sendMessage}>
          <img src={send} alt="send" className="sendImg" />
        </div>
      </div>
    </div>
  );
}

export default App;
