import React, { useState, useEffect } from "react";
import Markdown from "react-remarkable";

const TypeWriterAnimation = ({ answer, onAnimationFinished }) => {
  const [text, setText] = useState("");
  const [typedText, setTypedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setText(answer);
  }, [answer]);

  useEffect(() => {
    setCurrentIndex(0);
  }, [text]);

  useEffect(() => {
    if (currentIndex === 0) {
      setTypedText("");
    }
    //on animation end
    if (currentIndex === text?.length) {
      setTimeout(() => {
        onAnimationFinished();
      }, 1000);
    }
  }, [currentIndex]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === text?.length) {
        clearInterval(intervalId);
      } else {
        if (text?.[currentIndex] === "\n") {
          setTypedText((prevText) => prevText + "\n");
          setCurrentIndex((prevIndex) => prevIndex + 1);
          return;
        }

        setTypedText((prevText) => prevText + text?.[currentIndex] || "");
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    }, 20);

    return () => clearInterval(intervalId);
  }, [currentIndex, text]);

  return (
    <div className="TypeWriter-container">
      <div
        className="TypeWriterAnimation"
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        <Markdown>{typedText}</Markdown>
      </div>
    </div>
  );
};

export default TypeWriterAnimation;
